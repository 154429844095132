import React from "react"
import * as styles from "../stylesheets/components/bannerCustom.module.scss"

const BannerCustom = ({
  image,
  alt,
  height,
  header,
  subheader,
  bottomAlign,
}) => {
  return (
    <div className={styles.banner} style={{ height: `${height}px` }}>
      <div
        className={`${styles.mainTitle} ${bottomAlign && styles.bottomAlign}`}
      >
        <h1>{header}</h1>
        {subheader && <h3>{subheader}</h3>}
      </div>
      <img
        src={image}
        alt={alt}
        className={styles.bannerImage}
        style={{ height: `${height}px` }}
      />
    </div>
  )
}

export default BannerCustom
