import React from "react"
import BannerCustom from "../components/BannerCustom.js"
import Layout from "../components/layouts/Layout.js"
import * as styles from "../stylesheets/pages/sanfrancisco.module.scss"
import SFContactForm from "../components/SFContactForm"
import { graphql, useStaticQuery } from "gatsby"
import { RichText } from "prismic-reactjs"
import GatsbyLink from "../components/GatsbyLink"
import LazyLoad from "react-lazyload"

const SanFrancisco = () => {
  const data = useStaticQuery(graphql`
    query {
      prismicSanFrancisco {
        data {
          banner_header {
            text
          }
          banner_image {
            alt
            url
          }
          description {
            richText
          }
          description_title {
            text
          }
          icon {
            alt
            url
          }
        }
      }
    }
  `).prismicSanFrancisco.data

  return (
    <Layout
      title="San Francisco IT Support & Tech Services - TechCollective"
      description="Our San Francisco office provides on-site and remote support for businesses and nonprofits in the Bay Area. Get in touch with us today to learn more."
    >
      <div className={styles.mainContainer}>
        <BannerCustom
          image={data.banner_image.url}
          alt={data.banner_image.alt}
          header={data.banner_header.text}
          bottomAlign={true}
          height="300"
        />
        <div className={styles.descriptionContainer}>
          <h2>{data.description_title.text}</h2>

          <RichText
            render={data.description.richText}
            serializeHyperlink={GatsbyLink}
          />
        </div>

        <div className={styles.container} style={{ color: "white" }}>
          <div className={styles.formContainer}>
            <SFContactForm />
          </div>

          <LazyLoad>
            <iframe
              className={styles.map}
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d25223.81551643876!2d-122.42894974460592!3d37.79058019609396!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMzfCsDQ3JzM0LjQiTiAxMjLCsDIzJzUyLjciVw!5e0!3m2!1sen!2sus!4v1605286905864!5m2!1sen!2sus"
              title={"San Francisco TechCollective Map"}
            ></iframe>
          </LazyLoad>
        </div>
      </div>
    </Layout>
  )
}

export default SanFrancisco
